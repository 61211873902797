<template>
  <v-row
    no-gutters
    class="mt-1 pb-0"
  >
    <v-col
      cols="12"
      sm="4"
      md="4"
    >
      <vc-date-picker
        v-model="horarioComp.from"
        outlined
        dense
        hide-details
        mode="time"
        :model-config="modelConfig"
        class="d-flex justify-center;z-index: 1 !important"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            :label="$t('lbl.apertura')"
            outlined
            dense
            :value="inputValue"
            class="text-caption pr-3"
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>
      <!--<v-menu
        ref="menu"
        v-model="horarioFrom"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="horarioComp.from"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="horarioComp.from"
            :label="$t('lbl.apertura')"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="horarioFrom"
          v-model="horarioComp.from"
          full-width
          @click:minute="$refs.menu.save(horarioComp.from)"
        ></v-time-picker>
      </v-menu>-->
    </v-col>
    <v-col
      cols="12"
      sm="4"
      md="4"
    >
      <vc-date-picker
        v-model="horarioComp.to"
        outlined
        dense
        hide-details
        mode="time"
        :model-config="modelConfig"
        class="d-flex justify-center;z-index: 1 !important"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            :label="$t('lbl.cierre')"
            outlined
            dense
            :value="inputValue"
            class="text-caption pl-2"
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>
      <!--<v-menu
        ref="menuTo"
        v-model="horarioTo"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="horarioComp.to"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="horarioComp.to"
            :label="$t('lbl.cierre')"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="horarioTo"
          v-model="horarioComp.to"
          full-width
          @click:minute="$refs.menuTo.save(horarioComp.to)"
        ></v-time-picker>
      </v-menu>-->
    </v-col>
    <v-col
      cols="12"
      sm="1"
      md="1"
      offset-md="2"
    >
      <v-tooltip
        v-if="total > 1"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="error"
            @click="deleteHorarioComponent({ posOficce, posH, pos })"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
      <v-btn
        v-else
        fab
        x-small
      >
        <v-icon
          small
        >
          {{ icons.mdiTrashCan }}
        </v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip
        v-if="pos === 0"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="primary"
            @click="adicionarHorarioComponent({posOficce: posOficce, posH: posH})"
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    horarioComp: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    posH: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posOficce: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    total: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      horarioFrom: false,
      horarioTo: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
      modelConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
    }
  },

  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['adicionarHorarioComponent', 'deleteHorarioComponent']),
  },
}
</script>

<style>
  .vc-weekday, .vc-month, .vc-day, .vc-year {
    display: none;
  }
  /*.vc-weekday {
    color: rgb(255, 255, 255) !important;
  } */
</style>
