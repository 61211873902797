var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-row',{staticClass:"mt-0 pt-0 mb-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"9"}},[_c('v-slide-group',{staticClass:"mt-2",attrs:{"multiple":"","hide-details":""},model:{value:(_vm.horario.modelSemana),callback:function ($$v) {_vm.$set(_vm.horario, "modelSemana", $$v)},expression:"horario.modelSemana"}},_vm._l((_vm.semanas),function(item,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":"","x-small":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true)})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1","offset-md":"1"}},[(_vm.total > 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteHorarioOficceRent({pos: _vm.pos, posOficce: _vm.posOficce})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,522900070)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_c('v-btn',{staticClass:"mt-1",attrs:{"fab":"","x-small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1),(_vm.pos === 0)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-1 mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addHorarioOficceRent(_vm.posOficce)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,2669981626)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])])],1):_vm._e()],1),_c('v-row',{staticClass:"mt-0 pt-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.horario.horarios),function(horarioComp,i){return _c('HorarioComponent',{key:i,attrs:{"horario-comp":horarioComp,"pos":i,"pos-h":_vm.pos,"pos-oficce":_vm.posOficce,"total":_vm.horario.horarios.length}})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-checkbox',{staticClass:"my-1",attrs:{"label":_vm.$t('lbl.time_completo'),"hide-details":""},on:{"click":function($event){return _vm.setTimeCompletoOficinal({ posOficce: _vm.posOficce, pos: _vm.pos, isTimeCompleto: _vm.horario.is_time_completo })}},model:{value:(_vm.horario.is_time_completo),callback:function ($$v) {_vm.$set(_vm.horario, "is_time_completo", $$v)},expression:"horario.is_time_completo"}})],1)],1),(_vm.pos != _vm.total - 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-divider')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }