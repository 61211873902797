<template>
  <fragment>
    <v-row
      no-gutters
      class="mt-0 pt-0 mb-2"
    >
      <v-col
        cols="12"
        sm="9"
        md="9"
      >
        <v-slide-group
          v-model="horario.modelSemana"
          multiple
          hide-details
          class="mt-2"
        >
          <v-slide-item
            v-for="(item, index) in semanas"
            v-slot="{ active, toggle }"
            :key="index"
          >
            <v-btn
              class=""
              :input-value="active"
              active-class="primary white--text"
              depressed
              rounded
              x-small
              @click="toggle"
            >
              {{ item }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-col
        cols="12"
        sm="1"
        md="1"
        offset-md="1"
      >
        <v-tooltip
          v-if="total > 1"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 mr-2"
              fab
              dark
              x-small
              v-bind="attrs"
              color="error"
              v-on="on"
              @click="deleteHorarioOficceRent({pos: pos, posOficce: posOficce})"
            >
              <v-icon
                small
              >
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
        <v-btn
          v-else
          class="mt-1"
          fab
          x-small
        >
          <v-icon
            small
          >
            {{ icons.mdiTrashCan }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="pos === 0"
        cols="12"
        sm="1"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1 mx-2"
              fab
              dark
              x-small
              color="primary"
              @click="addHorarioOficceRent(posOficce)"
            >
              <v-icon
                v-bind="attrs"
                small
                v-on="on"
              >
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      class="mt-0 pt-0"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-row
          no-gutters
        >
          <HorarioComponent
            v-for="(horarioComp, i) in horario.horarios"
            :key="i"
            :horario-comp="horarioComp"
            :pos="i"
            :pos-h="pos"
            :pos-oficce="posOficce"
            :total="horario.horarios.length"
          />
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-checkbox
          v-model="horario.is_time_completo"
          :label="$t('lbl.time_completo')"
          hide-details
          class="my-1"
          @click="setTimeCompletoOficinal({ posOficce: posOficce, pos, isTimeCompleto: horario.is_time_completo })"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="pos != total - 1">
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiEyePlus,
} from '@mdi/js'
import HorarioComponent from './HorarioComponent.vue'

export default {
  components: {
    HorarioComponent,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    horario: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posOficce: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    total: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiEyePlus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,

      semanas: [],
    }
  },

  created() {
    this.isLoading = true

    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')
  },

  methods: {
    ...mapMutations([
      'addHorarioOficceRent',
      'deleteHorarioOficceRent',
      'deleteHorario',
      'adicionarHorarioComponent',
      'setTimeCompletoOficinal',
    ]),
  },
}
</script>
