<template>
  <v-row
    no-gutters
    class="mt-0 pt-1"
  >
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-text-field
        v-model="telefono.number"
        :label="$t('lbl.telefono')"
        outlined
        dense
        hide-details
        :prefix="`+${telefono.code}`"
        class="text-caption pr-2"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      md="3"
    >
      <v-text-field
        v-model="telefono.ext"
        label="Ext."
        outlined
        dense
        hide-details
        class="text-caption pr-1"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip
        v-if="total > 1"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1"
            fab
            dark
            x-small
            color="error"
            @click="deleteTelefonoOficceRent({pos: pos, posOficce: posOficce})"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
      <v-btn
        v-else
        class="mt-1"
        fab
        x-small
      >
        <v-icon
          small
        >
          {{ icons.mdiTrashCan }}
        </v-icon>
      </v-btn>
    </v-col>
    <v-col
      v-if="pos === 0"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 mx-2 ml-3"
            fab
            dark
            x-small
            color="primary"
            @click="addTelefonoOficceRent(posOficce)"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    telefono: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posOficce: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    total: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },

  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations([
      'addTelefonoOficceRent',
      'deleteTelefonoOficceRent',
    ]),
  },
}
</script>

<style scoped>
  /*.v-input input {
      max-height: 32px;
  }*/
</style>
