var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-1 pb-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('vc-date-picker',{staticClass:"d-flex justify-center;z-index: 1 !important",attrs:{"outlined":"","dense":"","hide-details":"","mode":"time","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({staticClass:"text-caption pr-3",attrs:{"label":_vm.$t('lbl.apertura'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.horarioComp.from),callback:function ($$v) {_vm.$set(_vm.horarioComp, "from", $$v)},expression:"horarioComp.from"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('vc-date-picker',{staticClass:"d-flex justify-center;z-index: 1 !important",attrs:{"outlined":"","dense":"","hide-details":"","mode":"time","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({staticClass:"text-caption pl-2",attrs:{"label":_vm.$t('lbl.cierre'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.horarioComp.to),callback:function ($$v) {_vm.$set(_vm.horarioComp, "to", $$v)},expression:"horarioComp.to"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1","offset-md":"2"}},[(_vm.total > 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteHorarioComponent({ posOficce: _vm.posOficce, posH: _vm.posH, pos: _vm.pos })}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,3546377434)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_c('v-btn',{attrs:{"fab":"","x-small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[(_vm.pos === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.adicionarHorarioComponent({posOficce: _vm.posOficce, posH: _vm.posH})}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,3243899063)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }